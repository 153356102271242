import { Button, ButtonProps, parseThemeColor } from "@mantine/core";

import { DefaultMantineColorName } from "ui/theme/types";

import classNames from "./Button.module.css";

function getAttrs({
  color,
  variant,
}: {
  color: DefaultMantineColorName;
  variant: ButtonProps["variant"];
}): {
  color: DefaultMantineColorName;
  variant: ButtonProps["variant"];
} {
  if (variant === "primary") return { color: "lime", variant: "filled" };
  if (variant === "secondary") return { color: "lime", variant: "light" };
  if (variant === "tertiary") return { color: "gray", variant: "light" };
  return { color, variant };
}

export const buttonComponentSettings = Button.extend({
  defaultProps: {
    variant: "light",
    color: "gray",
  },
  vars: (theme, props) => {
    const { color: rawColor, variant: rawVariant } = props;
    const { color, variant } = getAttrs({
      // Can assert bc default props
      color: rawColor as DefaultMantineColorName,
      variant: rawVariant,
    });

    if (variant === "filled") {
      const bgColorIndex =
        color === "lime"
          ? 3
          : // This exception supports the legacy bay filled button, which used to be our primary.
            color === "bay"
            ? 7
            : 6;
      const backgroundVar = `var(--mantine-color-${color}-${bgColorIndex})`;
      const backgroundVarHover = `var(--mantine-color-${color}-${bgColorIndex + 1})`;
      const fontColorVar =
        color === "lime" ? `var(--mantine-color-bay-7)` : `var(--mantine-color-white)`;
      const borderColorVar = `calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-${color}-5)`;
      return {
        root: {
          "--button-bg": backgroundVar,
          "--button-hover": backgroundVarHover,
          "--button-color": fontColorVar,
          "--button-bd": borderColorVar,
        },
      };
    }

    if (variant === "light") {
      const bgColorIndex = color === "gray" ? 1 : 0;
      const backgroundVar = `var(--mantine-color-${color}-${bgColorIndex})`;
      const backgroundVarHover = `var(--mantine-color-${color}-${bgColorIndex + 1})`;
      const fontColorVar =
        color === "lime" ? `var(--mantine-color-bay-7)` : `var(--mantine-color-${color}-7)`;
      const borderColorVar = `calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-${color}-${bgColorIndex + 2})`;

      return {
        root: {
          "--button-bg": backgroundVar,
          "--button-hover": backgroundVarHover,
          "--button-color": fontColorVar,
          "--button-bd": borderColorVar,
        },
      };
    }

    if (variant === "outline" || variant === "subtle") {
      const parsed = parseThemeColor({ color, theme });

      // isaac: i dont understand the logic below from `fontColorVar` on so im just making sure it works on theme for the colors we care about.
      if (
        parsed.isThemeColor &&
        parsed.shade === undefined &&
        ["green", "red", "bay"].includes(parsed.color)
      ) {
        if (parsed.color === "bay") {
          return {
            root: {
              "--button-bg": `var(--mantine-color-${parsed.color}-3)`,
              "--button-hover": `var(--mantine-color-${parsed.color}-4)`,
              "--button-color": `var(--mantine-color-${parsed.color}-7)`,
              "--button-bd": `calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-${parsed.color}-7)`,
            },
          };
        }

        return {
          root: {
            "--button-bg": `var(--mantine-color-${parsed.color}-2)`,
            "--button-hover": `var(--mantine-color-${parsed.color}-3)`,
            "--button-color": `var(--mantine-color-${parsed.color}-9)`,
            "--button-bd": `calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-${parsed.color}-7)`,
          },
        };
      }

      const fontColorVar =
        color === "lime" ? `var(--mantine-color-bay-7)` : `var(--mantine-color-${color}-7)`;
      const backgroundVar = `var(--mantine-color-white)`;
      const bgColorIndex = color === "gray" ? 2 : 0;
      const backgroundVarHover = `var(--mantine-color-${color}-${bgColorIndex})`;
      const borderColorIndex = color === "lime" ? 5 : color === "gray" ? 3 : 7;
      const borderColorVar =
        variant === "outline"
          ? `calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-${color}-${borderColorIndex})`
          : undefined;

      return {
        root: {
          "--button-bg": backgroundVar,
          "--button-hover": backgroundVarHover,
          "--button-color": fontColorVar,
          "--button-bd": borderColorVar,
        },
      };
    }

    return { root: {} };
  },
  classNames,
});
