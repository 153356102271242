import { ThemeIcon, parseThemeColor } from "@mantine/core";

export const themeIconComponentSettings = ThemeIcon.extend({
  defaultProps: {
    color: "gray.6",
    variant: "transparent",
    size: "sm",
  },
  vars: (theme, props) => {
    const { color, variant } = props;
    const parsed = parseThemeColor({ color, theme });

    if (variant === "light" && parsed.isThemeColor && parsed.shade === undefined) {
      if (parsed.color === "bay") {
        return {
          root: {
            "--ti-bg": `var(--mantine-color-${parsed.color}-3)`,
            "--ti-color": `var(--mantine-color-${parsed.color}-7)`,
          },
        };
      }

      return {
        root: {
          "--ti-bg": `var(--mantine-color-${parsed.color}-2)`,
          "--ti-color": `var(--mantine-color-${parsed.color}-9)`,
        },
      };
    }

    return {
      root: {},
    };
  },
});
